import React from 'react';
import Header from './../Components/Header'
import About from './../Components/About'
import Contact from './../Components/Contact'
import Services from './../Components/Services'
import Reviews from './../Components/Reviews'
import Seo from './../Components/SEO'

export default function index() {
  return (
    <div className="">
      <Seo title="Home" />
      <Header headline="Simplify managing maintenance" bg="bg-white" id="services" body="At Brenk Facility Maintenance, we help companies and organizations plan to prevent failure so they can minimize disruptions in their day to day operations. Our expert team works with you to repair and assess your building assets, document their conditions, and put together a plan so you can prevent future breakdowns and save money." />
      <Services />
      <Header headline="What our customers are saying" bg="bg-blue-50" id="reviews"/>
      <Reviews />
      <Header headline="About us" bg="bg-white" id="about"/>
      <About />
      <Header headline="Contact" bg="" id="contact"/>
      <Contact />
    </div>
  )
}

