import React from 'react';
import commercial from "./../images/storage.jpg";

export default function Example() {
  return (
    <div className="relative bg-white pt-16 pb-32 overflow-hidden">
      <div className="relative">
        <div className="xl:mx-auto xl:max-w-7xl xl:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-24">
          <div className="px-4 max-w-2xl mx-auto sm:px-6 xl:py-16 xl:max-w-none xl:mx-0 xl:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-blue-600">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>
                </span>
              </div>
              <div className="mt-6">
                <h2 className=" font-extrabold tracking-tight text-gray-900">
                  Commercial Maintenance
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                    Brenk Facility Maintenance serves 84 facilities and counting. We specialize in storage facilities and have become a trusted brand with every facility we work with. Our focus is on providing quality work in a timely fashion to ensure you think of us first when a new problem arises. The following is a brief list of some of the services we provide.
                </p>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <ul className="text-gray-500 text-lg">
                <li>Electircal</li>
                <li>Plumbing</li>
                <li>Indoor/Outdoor Lighting</li>
                <li>HVAC</li>
                <li>Doors and Locks</li>
                <li>Roll-up Doors</li>
                <li>Window Repair</li>
                <li>Landscaping</li>
                <li>Irrigation</li>
                <li>Fencing and Gates</li>
                <li>Flooring</li>
                <li>Painting</li>
                <li>Remodeling</li>
                <li>Water Remediation</li>
              </ul>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 xl:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 xl:px-0 xl:m-0 xl:relative xl:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 xl:absolute xl:left-0 xl:h-full xl:w-auto xl:max-w-none"
                src={commercial}
                alt="Commercial work"
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}