import React from 'react';


const people = [
  {
    name: 'Devin Crow',
    title: 'CubeSmart - District Manager',
    review: 'This is a reference for Daryl Brenk and Brenk Construction. Daryl has been a vender for CubeSmart thoughout our Austin and San Antonio markets for 4 years at 30+ different storage facilities. We use him for a long list of tasks, both big and small. Roof repairs, plumbing, door repair, painting, power washing, electrical, construction, emergency water remediation, debris removal.... you name it and Brenk has probably done it for us. Daryl is very responsive and reasonably priced compared to most vendors and I am more than happy to promote his business.',
  },
  {
    name: 'Janice Buckland',
    title: 'Extra Space Storage - Store Manager',
    review: 'To whom the may concern. My name is Janice Buckland and I am the Manger for Extra Space Storage at the 290 W location. He has worked for my store and at several of our other locations also. We have done business with his company for the last year. I would highly recommend Brenk Construction LLC... Daryl Brenk is the owner and is hands on ever job he bids and does. They do good work and are very reasonable.',
  },
  {
    name: 'David Fralic',
    title: 'Sleep Inn - Director of Hotel',
    review: "I first met Daryl Brenk 8 years ago, as he oversaw the General Contractor building of the Sleep Inn and Suites for the owner of the hotel and was quickly impressed with Daryl's knowledge od construction, and the way he heard others point out problems and Daryl had solutions that everyone agreed upon, the other impressive are I saw was his attention to detail as it really was wonderful to see that type of Professionalism. After we opened we could call Daryl and he would say I will be out there at this day and time and he was always early and i like that in a person and over the past 7 1/2 years when I have a need for Daryl or his company, there was no job to big or small that Daryl could not handle and the results of his work shows in this hotel and I still use Brenk Construction to date. Brenk Construction is a company that is trustworthy, responsible, dependable and stands by their work and word.",
  }
]

export default function Example() {
  return (
    <div className=" pb-14 px-4 bg-blue-50">
        <ul className=" grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 container mx-auto">
        {people.map((person) => (
            <li key={person.name} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
            <div className="w-full flex items-center justify-between p-8 space-x-6">
                <div className="flex-1 ">
                <div className="flex flex-col mb-4">
                    <p className="text-gray-900 text-lg font-medium ">{person.name}</p>
                    <span className=" px-2 py-0.5 text-gray-800 text-md font-medium bg-blue-100 rounded-full">
                    {person.title}
                    </span>
                </div>
                <p className="mt-1 text-gray-500">{person.review}</p>
                </div>
            </div>
            </li>
        ))}
        </ul>
    </div>  
  )
}