import React from 'react';
import family from './../images/family.jpg';

export default function About() {
    return (
        <>
            <div className="xl:pb-40 bg-white">
                <div className="pb-16 bg-blue-500 xl:pb-0 xl:z-10 xl:relative">
                    <div className="xl:mx-auto xl:max-w-9xl xl:px-8 xl:grid xl:grid-cols-2 xl:gap-8">
                        <div className="relative xl:-my-8">
                            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 xl:hidden bg-white" />
                                <div className="mx-auto max-w-xl px-4 sm:max-w-3xl sm:px-6 xl:p-0 xl:h-full">
                                    <div className="aspect-w-10 aspect-h-10 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-16 xl:aspect-none xl:h-full">
                                        <img
                                            className="object-cover xl:h-full xl:w-full"
                                            src={family}
                                            alt="family"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12 xl:m-0  xl:pl-8">
                                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 xl:px-0 xl:py-20 xl:max-w-none">
                                    <blockquote>
                                    <div>
                                        <svg
                                        className="h-12 w-12 text-white opacity-25"
                                        fill="currentColor"
                                        viewBox="0 0 32 32"
                                        aria-hidden="true"
                                        >
                                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                        </svg>
                                        <p className="mt-6 text-xl font-medium text-white">
                                            Brenk LLC. is a Central Texas based company committed to delivering the best quality of work while building personal relationships with our clients. We are your one stop shop when it comes to your construction needs. We work in both residential and commercial environments and specialize in solving difficult problems. With each project, we strive to meet the needs and requirements of our clients while guiding them throughout the project to ensure their vision becomes reality.
                                        </p>
                                    </div>
                                    <footer className="mt-6">
                                        <p className="text-base font-medium text-white">Daryl Brenk</p>
                                        <p className="text-base font-medium text-white">Owner</p>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
  }