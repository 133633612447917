import React from  'react';

export default function Header({tag, headline, body, bg, id}) {
    return (
      <div className={bg} id={id}>
        <div className="max-w-7xl mx-auto pt-16 pb-10 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8 container">
          <div className="text-center">
            <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase">{tag}</h2>
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              {headline}
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
              {body}
            </p>
          </div>
        </div>
      </div>
    )
  }